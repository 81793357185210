import React from 'react';
import Layout from '../shared/Layout';
import CenterLayout from '../shared/CenterLayout';
import SubscribeBox from '../components/SubscribeBox';
import subscribeStyles from './subscribe.module.scss';
import Head from '../shared/Head';

const Subscribe = () => {
    return (
        <Layout>
            <Head title={`Abonare`}/>
            <CenterLayout>
                <h1 className={subscribeStyles.subscribetitle}>
                    Un newsletter cu idei simple și practice despre programare,
                    dezvoltare web, tutoriale și sugestii utile. 
                </h1>
                <SubscribeBox />
                <p>
                    Dacă îți place ce ai găsit pe aici și vrei mai mult nu ezita să te abonezi 
                    la newsletter. Îți voi trimite săptămînal articole despre programare, dezvoltare web,
                    tutoriale despre JavaScript, HTML, CSS, React, Angular, Vue și multe altele.
                    În plus pe lîngă toate astea îți voi trimite pe email și un material despre pașii pe care 
                    trebuie să îi faci pentru a avea succes în dezvoltarea de aplicații web.
                </p>
                <p>Abilitatea de a folosi calculatorul la alt nivel decât cel al distracției și al simplei utilizări de zi cu zi
                    îți poate aduce beneficii enorme mai ales că tot mai multe lucruri depind de calculator și internet. Așa că scopul
                    acestui blog e să te ajute să înveți lucrurile de bază și dacă e nevoie să primești sugestii care să te poată duce
                    la nivelul urmator.
                </p>
                <p>
                    Lasă-ți numele și adresa de email în formularul de mai jos și te voi ține la curent cu tot
                    ce se întâmpla pe aici.
                </p>
                <SubscribeBox />
            </CenterLayout>
        </Layout>
    );
}
export default Subscribe;