import React from 'react';
import SidebarForm from '../SidebarForm';
import subscribeBoxStyles from './subscribeBox.module.scss';


const SubscribeBox = () => {
    return (
        <div className={subscribeBoxStyles.formcontainer}>
            <div>
                <div className={subscribeBoxStyles.imagecontainer}>
                   
                </div>
            </div>
            <div>
                <h1 className={subscribeBoxStyles.subscribetitle}>Abonează-te la newsletter!</h1>
                <SidebarForm />
            </div>
        </div>
    );
}
export default SubscribeBox;